<template>
  <div>
    <section
      id="card-images"
    >
      <b-row>
        <b-col>
          <b-card
            v-for="(item, index) in options"
            :key="item._id"
            :clipindex="index"
            border-variant=""
            :class="(index == 0) ? 'active-clip' : ''"
          ><vue-plyr
            :options="plyrOptions"
          >
            <video
              controls
              crossorigin
              playsinline
              :poster="item.clipThumbnailUrl"
            >
              >
              <source
                size="
              1080"
                :src="`https://vod.brimecdn.com/n/ax9rag1oiyr8/b/brime/o/clip/${item.channelID}/${item._id}`"
                type="video/mp4"
              >
            </video></vue-plyr><span
            id="streamTitle"
          ><b-avatar
             size="40"
             src="https://us-central-1-s3.netdepot.com/brime/ShareX/2021/03/BrimeLogoTransparentBW.png"
             variant="light-primary"
             class="badge-minimal mr-1"
           /><span
             id="title"
             style="padding-left: 50px; margin-top: -40px; color: #FFF"
           >{{ item.stream.title }}</span>
            <span
              id="stream"
              style="padding-left: 50px;"
            >
              <router-link :to="item.channel"><strong>{{ item.channel }}</strong></router-link><br>
              <span
                id="clipper"
                style="padding-left: 50px; color: rgba(255, 255, 255, 0.6)"
              >
                {{ item.clipName }}</span>
            </span><div
              class="float-right voteControls"
              style="margin-top: -5em"
            ><b-button
              :variant="(item.state == 1) ? 'primary' : 'outline-primary'"
              class="voteButton upVoteButton"
              @click="upvote(index), next(index)"
            ><feather-icon
              icon="ArrowUpIcon"
              width="1.5em"
              height="1.5em"
            /></b-button><div class="score"><strong>{{ item.upvotes }}</strong></div><b-button
              :variant="(item.state == -1) ? 'primary' : 'outline-primary'"
              class="voteButton downVoteButton"
              @click="downvote(index), next(index)"
            ><feather-icon
              icon="ArrowDownIcon"
              width="1.5em"
              height="1.5em"
            /></b-button></div></span>
            <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BButton,
} from 'bootstrap-vue'

import Vue from 'vue'

let prev = 0
const $window = window.$(window)
const stickyMenu = window.$('.stickyMenu')

$window.on('scroll', () => {
  const scrollTop = $window.scrollTop()
  stickyMenu.toggleClass('hidden', scrollTop > prev)
  prev = scrollTop
})
Vue.filter('capitalize', string => {
  const capitalFirst = string.toUpperCase()
  return capitalFirst
})
const prefix = 'https://content.brimecdn.com/live/'
const suffix = '/thumbnail.jpg'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton,
  },
  data() {
    return {
      clipIndex: '',
      plyrOptions: {
        autoplay: false,
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'download',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
      },
      tabData: [
        { home: '' },
        { profile: '' },
        { disabled: '' },
        { about: '' },
      ],
      options: [],
      test: {},
      prefix,
      suffix,
    }
  },
  /* eslint-disable */
  mounted() {
    document.title = 'Clip Review 👏 - Brime'
    setTimeout(() => {
      const clip = document.querySelector('[clipIndex=\'0\']')

      clip.querySelector('video').play()
    }, 500)
  },
  async beforeCreate() {
    const clips = await window.brime.clipReviewGetRecentClips(this.targetSince, this.limit, this.skip)

for (const clip of clips) {
    if (window.brime.getAccessToken()) {
        const voteState = await window.brime.getClipVoteState(clip._id)
        const channelData = await window.brime.getChannel(clip.channelID)
        
        clip.state = voteState
        clip.channel = channelData.channel
    }

    this.options.push(clip)
}
  },
  methods: {
 upvote(index) {
    const clip = this.options[index]

    const oldState = clip.state;

    clip.upvotes -= oldState

    if (oldState == 1) {
        window.brime.removeClipVote(clip._id)
        clip.state = 0
    } else {
        window.brime.upvoteClip(clip._id)
        clip.state = 1
        clip.upvotes++
    }
},
downvote(index) {
    const clip = this.options[index]

    const oldState = clip.state

    clip.upvotes -= oldState

    if (oldState == -1) {
        window.brime.removeClipVote(clip._id)
        clip.state = 0
    } else {
        window.brime.downvoteClip(clip._id)
        clip.state = -1
        clip.upvotes--
    }
},
    onVideoLoad(index) {
      if (index === 0) {
        const clip = document.querySelector(`[clipIndex='${index}']`)

        clip.querySelector('video').pause()
      }
    },
    next(index) {
      const currentClip = document.querySelector(`[clipindex='${index}']`)
      const nextClip = document.querySelector(`[clipindex='${index + 1}']`)

      currentClip.querySelector('video').pause()
      Array.from(document.querySelectorAll('.active-clip')).forEach(e => {
        e.classList.remove('active-clip')
      })
      nextClip.scrollIntoView({ block: 'center', behavior: 'smooth' })
      nextClip.classList.add('active-clip')
      nextClip.querySelector('video').play()
    },
    pauseAllClips() {
      Array.from(document.querySelectorAll('video')).forEach(video => {
        video.pause()
      })
    },
  },
}
</script>
<style scoped>
.score{
text-align: center;
}
.cardStuff{
display: block !important;
}
.col{
padding-left: unset;
padding-right: unset;
}
.clips-col {
    position: relative;
    left: 50%;
    width: 50vw;
    transform: translateX(-50%);
}
.stickyMenu{
       position: fixed;
        overflow: hidden;
        z-index: 2400;
        opacity: 0.70;
        margin: auto;
        left: 0;
        z-index: 20;
        padding-top: 20px;
        width: 102vw;
        top: 60px !important;
        opacity: unset;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
background: rgb(131,58,180);
background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 100%);
}
/* Scrollbar styles */
.dark-layout .card {
  box-shadow: unset;
 box-shadow: 0px 5px 10px 0 rgb(12 20 39 / 48%);
}
body {
  overflow: hidden; /* Hide scrollbars */
}
#style-4::-webkit-scrollbar-track
{
background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}
.card:hover img {
  filter: saturate(1.2)
}

.card img {
  filter: saturate(1);
  transition: all 0.2s;
}
#style-4::-webkit-scrollbar-thumb
{
background-color: #000000;
border: 2px solid #555555;
}

.card {
  display: flex;
  background-color: unset;
  max-height: 80vh;
  max-width: 60vw;
  margin: auto;
  margin-bottom: 40px;
}
@media all and (max-width: 767px) {
.card {
  display: none;
  background-color: unset;
  max-height: 80vh;
  max-width: 100vw;
  margin: auto;
  margin-bottom: 20px;
}
 .active-clip {
    display: flex !important;
  }
#title{
display: none !important;
}
.card-body{
padding: 0 !important;
}
#stream{
padding-left: 0 !important;
}
.voteControls{
margin-top: -3.5rem !important;
}
}
.card-body {
  padding: .75rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#category {
  margin-top: auto;
  text-align: left;
  font-weight: bold;
}
#title {
  color: #b4b7bd;
  margin-top: auto;
  text-align: left;
  font-size: 14px;
    display: block;
  text-align: left;
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#streamtitle {
  font-size: 14px;
  line-height: 18px;
}
#stream {
  font-size: 20px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  height: 100%;
}
#clipper {
  font-size: 14px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  height: 100%;
}
#streamTitle {
  color: #7367f0;
  font-size: 14px;
  display: block;
  text-align: left;
  padding-top: 25px;
  padding-bottom: 5px;
}
</style>
